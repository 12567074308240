@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Borel&family=Montserrat:wght@100;400&family=Noto+Serif+Vithkuqi&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto&display=swap');

:root {
  --primary-dark: #5651e5;
  --primary-light: #709dff;
  --secondary-dark: #F2F2F2;
  --logo: #0D2935;
}

@layer base {
  html {
    @apply scroll-smooth
  }
  body {
    @apply font-[Poppins]
  }
  li {
    @apply p-4 text-sm
  }
  button {
    @apply px-4 py-2 border bg-gradient-to-r from-[var(--primary-dark)] to-[var(--primary-light)] text-white rounded-md
  }
  h2 {
    @apply text-xl font-bold
  }
}

